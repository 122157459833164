@tailwind base;
@tailwind components;
@tailwind utilities;

#interest-form {
    min-width: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 1);
  font-family: "Copperplate", "Copperplate Gothic Light", sans-serif; /* Apply Copperplate font */
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: rgba(0, 255, 50, 4);
}

.right-buttons {
  display: flex;
  gap: 10px; /* Optional: space between the buttons */
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 255, 129, 1); /* Optional: Adds a shadow for readability */
}

.no-shadow {
  text-shadow: 1px 1px 2px rgba(0,0,0,0); /* No shadow */
}

.text-color {
  color: rgba(0, 255, 129, 1);
}

.animate-fade {
  opacity: 0;
  animation: fadeIn 2s ease forwards;
  transform: translateZ(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animatex {
  opacity: 0;
  transform: translateX(-100px); /* Initially positioned below */
  transition: transform 1s ease-out, opacity 1s ease-out; /* Smooth animation */
}

.vis {
  transform: translateX(0);
  opacity: 1;
}

.animatex.visible {
  opacity: 1;
  transform: translateY(0); /* Moves into view */
}

.animate {
  opacity: 0;
  transform: translateY(100px); /* Initially positioned below */
  transition: transform 1s ease-out, opacity 1s ease-out; /* Smooth animation */
}

.animate.visible {
  opacity: 1;
  transform: translateY(0); /* Moves into view */
}

.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-size: cover; /* Ensure the image covers the entire div */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */  
  text-align: center;
}

#info {
  position: absolute; /* Or 'fixed' if you want it to stay in place even when scrolling */
 /* top: 100vh; Position it just below the viewport */
  width: 100%; /* Optional: adjust the width */
  height: auto; /* Adjust the height as needed */
}

#home-about #sponsors {
  box-shadow: inset 20px 40px 20px rgb(113 113 122 / 0.05);
}

h1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: Adds a shadow for readability */
}

h3 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: Adds a shadow for readability */
}

.text-color {
  color: rgba(0, 255, 129, 1);
}

h2 {
  color: rgba(0, 255, 129, 1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: Adds a shadow for readability */
}

.image-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}